exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-confirmar-cadastro-tsx": () => import("./../../../src/pages/confirmar-cadastro.tsx" /* webpackChunkName: "component---src-pages-confirmar-cadastro-tsx" */),
  "component---src-pages-contato-tsx": () => import("./../../../src/pages/contato.tsx" /* webpackChunkName: "component---src-pages-contato-tsx" */),
  "component---src-pages-obrigado-contato-tsx": () => import("./../../../src/pages/obrigado-contato.tsx" /* webpackChunkName: "component---src-pages-obrigado-contato-tsx" */),
  "component---src-pages-obrigado-drops-seo-tsx": () => import("./../../../src/pages/obrigado-drops-seo.tsx" /* webpackChunkName: "component---src-pages-obrigado-drops-seo-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-templates-archive-blog-js": () => import("./../../../src/templates/archive/blog.js" /* webpackChunkName: "component---src-templates-archive-blog-js" */),
  "component---src-templates-archive-webstory-js": () => import("./../../../src/templates/archive/webstory.js" /* webpackChunkName: "component---src-templates-archive-webstory-js" */),
  "component---src-templates-single-page-js": () => import("./../../../src/templates/single/page.js" /* webpackChunkName: "component---src-templates-single-page-js" */),
  "component---src-templates-single-post-js": () => import("./../../../src/templates/single/post.js" /* webpackChunkName: "component---src-templates-single-post-js" */)
}

